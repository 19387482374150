import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { axisBottom, axisLeft, select } from 'd3';

const CombinedPlot = (props) => {
    const data1 = props.data1.sort((a,b) => a.up - b.up);
    const data2 = props.data2.sort((a,b) => a.up - b.up);
    const width = props.width;
    const height = props.height;
    const selectedUser = props.selectedUser;
    const alignedDataUser1 = props.alignedDataUser1;
    const alignedDataUser2 = props.alignedDataUser2;

    const [alignedData, setAlignedData] = useState(alignedDataUser1)

    useEffect(() => {
        if (selectedUser === '1') {
            setAlignedData(alignedDataUser1)
        } else {
            setAlignedData(alignedDataUser2)
        }
    }, [selectedUser])

    const padding = 0;
    const margin = Object.freeze({
        top: 0, 
        right: 100, 
        bottom: 100, 
        left: 100
    }); 

    const svgCombinedPlot = useRef();

    // This effect will render the visualization and contains all the D3 code
    useEffect(() => {
        const innerHeight = height - margin.top - margin.bottom;
        const innerWidth = width - padding - margin.left - margin.right;

        const g = select(svgCombinedPlot.current)

        // Define the pattern
        g.selectAll('defs')
            .data([1])
            .join('defs')
            .selectAll('pattern')
            .data([1])
            .join('pattern')
                .attr('id', 'diagonalHatch')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 4)
                .attr('height', 4)
            .selectAll('path')
            .data([1])
            .join('path')
                .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
                .attr('stroke', '#000000')
                .attr('stroke-width', 1);

        if (alignedData.length > 0) {
            // Get indexes where a null value is added
            const index = []
            alignedData[0].map((d,i) => d === 0 ? index.push(i) : d)
            alignedData[1].map((d,i) => d === 0 ? index.push(i) : d)

            // Remove all letters at indexes in index
            const aligned1 = alignedData[0].filter((d,i) => !index.includes(i))
            const aligned2 = alignedData[1].filter((d,i) => !index.includes(i))

            // Filter the data on outliers (used to get the domain of the y axis)
            const filtered1 = aligned1.filter(d => d.outlier === false);
            const filtered2 = aligned2.filter(d => d.outlier === false);

            // Minimal and maximal value for the y-axis
            const minTime = aligned2.length > 0 ? Math.min(d3.min(aligned1, d => {return d.relativeStart}), d3.min(aligned2, d => {return d.relativeStart})) : d3.min(aligned1, d => {return d.relativeStart});
            const maxTime = aligned2.length > 0 ? Math.max(d3.max(filtered1, d => {return d.relativeEnd}), d3.max(filtered2, d => {return d.relativeEnd})) : d3.max(filtered1, d => {return d.relativeEnd});
    
            // Define y axis scale
            const y = d3.scaleLinear()
                .domain([minTime, maxTime])
                .range([innerHeight, 0])
            
            // Define x axis scale
            const x = d3.scaleBand()
                .domain(aligned1.map((d,i) => i))
                .range([0, innerWidth])
                .padding(0.2)
   
            // Define axes and what scales to use
            const xAxis = (g) => g
                .call(axisBottom(x)
                    .tickFormat((d) => aligned1.find((l,i) => i === d).key)
                    .tickSizeOuter(0)
                    .tickSizeInner(0) );
            const yAxis = (g) => g
                .call(axisLeft(y)
                    .tickSizeOuter(0)
                    .tickSizeInner(0));

            // Add a rectangle for each keystroke
            g.selectAll('.user1')
                .data(aligned1)
                .join('rect')
                .attr('class', 'user1')
                .attr('x', (d,i) => x(i))
                .attr('y', d => d.outlier === true ? 0 : y(d.relativeEnd))
                .attr('width', x.bandwidth())
                .attr('height', d => y(d.relativeStart) - y(d.relativeEnd))
                .attr('opacity', 0.5)
                .attr('fill', d => d.outlier === true ? 'white' : '#11384A')
                .attr('stroke', '#11384A')
                .attr('stroke-width', d => d.outlier === true ? 2 : 0)
                .attr('transform', `translate(${margin.left}, 0)`)

            const line = d3.line()
                .x((d,i) => x(i) + (x.bandwidth()/2) + margin.left)
                .y(d => y(d.relativeStart) + (y(d.relativeEnd) - y(d.relativeStart))/2)
                .curve(d3.curveMonotoneX)

            // Add striped pattern if outlier
            g.selectAll('.user1pattern')
                .data(aligned1)
                .join('rect')
                .attr('class', 'user1pattern')
                .attr('x', (d,i) => x(i))
                .attr('y', d => d.outlier === true ? 0 : y(d.relativeEnd))
                .attr('width', x.bandwidth())
                .attr('height', d => y(d.relativeStart) - y(d.relativeEnd))
                .attr('fill', d => d.outlier === true ?'url(#diagonalHatch)' : 'none')
                .attr('transform', `translate(${margin.left}, 0)`)

            // Remove the rectangles of the second user
            g.selectAll('.user2').remove()

            // Add rectangle for each keystroke
            if (data2.length > 0) {
                g.selectAll('.user2')
                    .data(aligned2)
                    .join('rect')
                    .attr('class', 'user2')
                    .attr('x', (d,i) => x(i))
                    .attr('y', d => d.outlier === true ? 0 : y(d.relativeEnd))
                    .attr('width', x.bandwidth())
                    .attr('height', d => y(d.relativeStart) - y(d.relativeEnd))
                    .attr('opacity', 0.5)
                    .attr('fill', d => d.outlier === true ? 'white' : '#0063B2FF')
                    .attr('stroke', '#0063B2FF')
                    .attr('stroke-width', d => d.outlier === true ? 2 : 0)
                    .attr('transform', `translate(${margin.left}, 0)`)

                // Add pattern if outlier
                g.selectAll('.user2pattern')
                    .data(aligned2)
                    .join('rect')
                    .attr('class', 'user2pattern')
                    .attr('x', (d,i) => x(i))
                    .attr('y', d => d.outlier === true ? 0 : y(d.relativeEnd))
                    .attr('width', x.bandwidth())
                    .attr('height', d => y(d.relativeStart) - y(d.relativeEnd))
                    .attr('fill', d => d.outlier === true ?'url(#diagonalHatch)' : 'none')
                    .attr('transform', `translate(${margin.left}, 0)`)

                g.selectAll('.user1path')
                    .data([1])
                    .join('path')
                    .attr('class', 'user1path')
                    .attr('d', line(aligned1))
                    .attr('fill', 'none')
                    .attr('stroke-width', 5)
                    .attr('stroke', '#11384A')
    
                g.selectAll('.user2path')
                    .data([1])
                    .join('path')
                    .attr('class', 'user2path')
                    .attr('d', line(aligned2))
                    .attr('fill', 'none')
                    .attr('stroke-width', 5)
                    .attr('stroke', '#0063B2FF')
            }
            
            g.select('g.yAxis')
                .call(yAxis)
                .call(g => g.select('.domain').remove())    //To remove the axis line
                .attr('transform', `translate(${margin.left}, 0)`)
            
            g.select('g.xAxis')
                .call(xAxis)
                .call(g => g.select('.domain').remove())
                .attr('transform', `translate(${margin.left}, ${innerHeight + 20})`)
                .attr('font-size', data1.length < 20 ? '23px' : '16px')
        } else {
            g.selectAll('*').html(null)
            g.selectAll('.user1').remove()
            g.selectAll('.user2').remove()
            g.selectAll('.user1path').remove()
            g.selectAll('.user2path').remove()

        };

    }, [data1, data2, props.data1, props.data2, alignedData, height, width, margin])

    return (
        <div>
            <svg 
                className='combinedPlot' 
                width={width} 
                height={height}
                ref={svgCombinedPlot}
            >
                <g className='xAxis' />
                <g className='yAxis' />
            </svg>
        </div>
    )
};

export default CombinedPlot;