import React, { useState, useEffect } from 'react'
import AutoSizer from "react-virtualized-auto-sizer";

// Import components
import CombinedPlot from './CombinedPlot';
import PlotLeft from './PlotLeft';
import PlotRight from './PlotRight';

// Function to get the median
const getMedian = (data) => {
    const medData = [...data];
    medData.sort((a,b) => a.flightTime - b.flightTime)
    medData.filter(d => d.flightTime > 0)

    const len = data.length;
    const mid = Math.ceil(len/2);
    const median = len % 2 === 0 ? (medData[mid].flightTime + medData[mid - 1].flightTime) / 2 : medData[mid - 1].flightTime;

    data.map(d => d.flightTime > Math.abs(4*median) ? d['outlier'] = true : d['outlier'] = false)

    return data;
};

// Align sequences
const alignSequences = (target, compared) => {
    let n_event = 0;

    if (target.length > 0) {
        let target_copy = [];
        let compared_copy = [];

        // Make a copy of the target array
        target_copy.push(...target);
        compared_copy.push(...compared);

        let j = 0;
        // While j is smaller than the length of the shortest sequence
        while (j < Math.min(target_copy.length, compared_copy.length)) {

            // If the states are not equal, add 0 at that index
            if (target_copy[j].key !== compared_copy[j].key) {
                n_event = n_event + 1;
                if (target_copy.length > compared_copy.length) {
                    // Target bigger
                    compared_copy.splice(j, 0, 0);
                } else { 
                    // Compared bigger or state not equal when they have the same length
                    target_copy.splice(j, 0, 0);
                };
            };

            j = j+1;
        };

        // If the length is not equal, add 0 at the end of the shortest sequence
        while(target_copy.length !== compared_copy.length) {
            n_event = n_event + 1;
            if (target_copy.length > compared_copy.length) {
                // Target bigger
                compared_copy.splice(j, 0, 0);
            } else {    // if (target_copy.length < compared_copy.length)
                // Compared bigger
                target_copy.splice(j, 0, 0);
            };

            j = j+1;
        };

        return [target_copy, compared_copy]  
    };            
};

const Plot = (props) => {
    const radioValue = props.radioValue;
    const data1 = props.data1;
    const data2 = props.data2;
    const data3 = props.data3;
    const selectedUser = props.selectedUser

    let alignedDataUser1 = [];
    let alignedDataUser2 = [];
    const onIsEqual = props.onIsEqual;
    const [text1, setText1] = useState('');
    const [text2, setText2] = useState('');
    const [text3, setText3] = useState('');

    useEffect(() => {
        setText1(data1.map(d => d.key))
    }, [data1])

    useEffect(() => {
        setText2(data2.map(d => d.key))
    }, [data2])

    useEffect(() => {
        setText3(data3.map(d => d.key))
    }, [data3])
    
    // Calculate the median for data1 and data2
    if(data1.length > 0) {
        getMedian(data1);

        if(data2.length > 0) {
            getMedian(data2);
            alignedDataUser1 = alignSequences(data1, data2)
        }

        if(data3.length > 0) {
            getMedian(data3);
            alignedDataUser2 = alignSequences(data1, data3)
        }
    };

    useEffect(() => {
        if(JSON.stringify(text1) === JSON.stringify(text2) && data1.length > 3 && data2.length > 3) {
            onIsEqual(true)
        } else {
            onIsEqual(false)
        };
    }, [text2])

    if (radioValue === 'combined') {
        return (
            <AutoSizer>
                {({ width, height }) => 
                  <CombinedPlot
                    data1 = {data1}
                    data2 = {selectedUser === 1 ? data2: data3}
                    width = {width}
                    height = {height}
                    alignedDataUser1 = {alignedDataUser1}
                    alignedDataUser2 = {alignedDataUser2}
                    selectedUser = {selectedUser}
                  />
                }
              </AutoSizer>
        )
    } else {
        return ( 
            <> 
                <div className='flexbox-combined-plot'>
                    <div className='flexbox-item-plot-right'>
                        <AutoSizer>
                            {({ width, height }) => 
                            <PlotLeft
                                data1 = {data1}
                                width = {width}
                                height = {height}
                            />
                            }
                        </AutoSizer>
                    </div>

                    <div className='flexbox-item-plot-left'>
                        <AutoSizer>
                        {({ width, height }) => 
                            <PlotRight 
                            data2 = {data2}
                            data3 = {data3}
                            width = {width}
                            height = {height}
                            selectedUser = {selectedUser}
                            />
                        }
                        </AutoSizer>
                    </div>
                </div>
            </>
        )
    }
};

export default Plot