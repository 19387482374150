import React from 'react'
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';

export default function Info() {  
    return (
        <Tooltip 
            title={
                <div>
                    <Typography variant='subtitle2'>
                        Select the Individual plots to compare your typing behaviour side by side, 
                        or choose the Combined plot for an overlapping view.
                    </Typography>
                    <br></br>
                    <Typography variant='subtitle2'>
                        Select User 1 to compare the first and second entry of user 1, or select User 2 to compare the typing behaviour of user 1 and user 2. 
                    </Typography>
                </div>
            }
        >
            <IconButton aria-label='info'>
                <InfoIcon />
            </IconButton>
        </Tooltip>
    )
};