import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { axisBottom, axisLeft, select } from 'd3';

const PlotRight = (props) => {
    const selectedUser = props.selectedUser;
    const data2 = props.data2.sort((a,b) => a.down - b.down);
    const data3 = props.data3.sort((a,b) => a.down - b.down);
    const width = props.width;
    const height = props.height;

    const [data, setData] = useState(data2);

    useEffect(() => {
        if (selectedUser === '1'){
            setData(data2)
        } else {
            setData(data3)
        }
    }, [selectedUser, data2])

    const padding = 0;
    const margin = Object.freeze({
        top: 0, 
        right: 100, 
        bottom: 100, 
        left: 100
    }); 

    const svgPlotRight = useRef();

    // This effect will render the visualization and contains all the D3 code
    useEffect(() => {
        const innerHeight = height - margin.top - margin.bottom;
        const innerWidth = width - padding - margin.left - margin.right;

        const g = select(svgPlotRight.current)

        // Define the pattern
        g.selectAll('defs')
        .data([1])
        .join('defs')
        .selectAll('pattern')
        .data([1])
        .join('pattern')
            .attr('id', 'diagonalHatch')
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('width', 4)
            .attr('height', 4)
        .selectAll('path')
        .data([1])
        .join('path')
            .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
            .attr('stroke', '#000000')
            .attr('stroke-width', 1);
        
        if (data.length > 0) {
            const filtered = selectedUser === '1' ? (props.data2).filter(d => d.outlier === false) : (props.data3).filter(d => d.outlier === false);

            const minTime = d3.min(data, d => {return d.relativeStart});
            const maxTime = d3.max(filtered, d => {return d.relativeEnd});
    
            // Define y axis scale
            const y = d3.scaleLinear()
                .domain([minTime, maxTime])
                .range([innerHeight, 0])
            
            // Define x axis scale
            const x = d3.scaleBand()
                .domain(data.map(d => d.id))
                .range([0, innerWidth])
                .padding(0.2)
    
            // Define axes and what scales to use
            const xAxis = (g) => g
                .call(axisBottom(x)
                    .tickFormat((d) => data.find((l) => l.id === d).key)
                    .tickSizeOuter(0)
                    .tickSizeInner(0) );
            const yAxis = (g) => g
                .call(axisLeft(y)
                    .ticks(10)
                    .tickSizeOuter(0)
                    .tickSizeInner(0));

            g.selectAll('.user2')
                .data(data)
                .join('rect')
                .attr('class', 'user2')
                .attr('x', d => x(d.id))
                .attr('y', d => d.outlier === true ? 0 : y(d.relativeEnd))
                .attr('width', x.bandwidth())
                .attr('height', d => y(d.relativeStart) - y(d.relativeEnd))
                .attr('fill', d => d.outlier === true ? 'white' : '#0063B2FF')
                .attr('opacity', 0.5)
                .attr('transform', `translate(${margin.left}, 0)`)
                .attr('stroke', '#0063B2FF')
                .attr('stroke-width', d => d.outlier === true ? 2 : 0)
                .on('click', (d,e) => console.log(e))

            const line = d3.line()
                .x(d => x(d.id) + (x.bandwidth()/2) + margin.left)
                .y(d => y(d.relativeStart) + (y(d.relativeEnd) - y(d.relativeStart))/2)
                .curve(d3.curveMonotoneX)

            g.selectAll('.user2path')
                .data([1])
                .join('path')
                .attr('class', 'user2path')
                .attr('d', line(data))
                .attr('fill', 'none')
                .attr('stroke-width', 5)
                .attr('stroke', '#0063B2FF')

            g.selectAll('.user2Outlier')
                .data(data.filter(d => d.outlier === true))
                .join('rect')
                .attr('class', 'user2Outlier')
                .attr('x', d => x(d.id))
                .attr('y', d => d.outlier === true ? 0 : y(d.relativeEnd))
                .attr('width', x.bandwidth())
                .attr('height', d => y(d.relativeStart) - y(d.relativeEnd))
                .attr('fill', 'url(#diagonalHatch)')
                .attr('transform', `translate(${margin.left}, 0)`)

            g.select('g.yAxis')
                .call(yAxis)
                .call(g => g.select('.domain').remove())    //To remove the axis line
                .attr('transform', `translate(${margin.left}, 0)`)
            
            g.select('g.xAxis')
                .call(xAxis)
                .call(g => g.select('.domain').remove())
                .attr('transform', `translate(${margin.left}, ${innerHeight + 20})`)
                .attr('font-size', data.length < 20 ? '23px' : '16px')
        } else {
            g.selectAll('.user2').remove()
            g.selectAll('*').html(null)
            g.selectAll('.user2path').remove()
        };

    }, [data, height, width, margin, selectedUser])

    return (
        <div>
            <svg 
                className='plotRight' 
                width={width} 
                height={height + 10}
                ref={svgPlotRight}
            >
                <g className='xAxis' />
                <g className='yAxis' />
            </svg>
        </div>
    )
};

export default PlotRight;