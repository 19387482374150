import React, {useState, useEffect} from 'react';

// Import components
import './App.css';
import Input from './Components/Input';
import Plot from './Components/Plot';
import Logo from './Components/Logo';
import Legend from './Components/Legend';
import StartOver from './Components/StartOver';

function App() {
  const [input1, setInput1] = useState([]);
  const [input2, setInput2] = useState([]);
  const [input3, setInput3] = useState([]);
  const [radioValue, setRadioValue] = useState('individual');
  const [isEqual, setIsEqual] = useState(false);
  const [startOver, setStartOver] = useState(false)
  const [selectedUser, setSelectedUser] = useState('1')

  useEffect(() => {
    if(startOver){
      setInput1([])
      setInput2([])
      setInput3([])
      setRadioValue('individual')
    }
  }, [startOver])

  useEffect(() => {
    if(input1.length > 0) {
      setStartOver(false)
    }
  }, [input1])

  return (
    <div className="flexbox-container">
      <Logo />
      <div className='flexbox-column'>
        {input3.length === 0 
          ? 
          <div className='flexbox-item-input'>
            <Input 
              onSetInput1 = {(input1) => setInput1(input1)}
              onSetInput2 = {(input2) => setInput2(input2)}
              onSetInput3 = {(input3) => setInput3(input3)}
            />
          </div> 
          : 
          <div className='flexbox-item-input'>
            <StartOver 
              isEqual={isEqual}
              onRadioChanged = {(radioValue) => setRadioValue(radioValue)}
              onStartOver={(startOver) => setStartOver(startOver)}
            />
          </div> 
        }
        {input1.length === 0 
          ?
          <div></div>
          : 
          <div className='flexbox-item-legend'>
            <Legend 
              data = {input1}
              selectedUser = {selectedUser}
              onSetSelectedUser = {(selectedUser) => setSelectedUser(selectedUser)}
            />
          </div>
        }
        <div className='flexbox-item-plot'>
          {input1.length > 0 
          ? 
            <Plot 
              radioValue = {radioValue}
              onIsEqual={(isEqual) => setIsEqual(isEqual)}
              data1 = {input1.slice(0, 40)}
              data2 = {input2.slice(0, 40)}  
              data3 = {input3.slice(0, 40)} 
              selectedUser = {selectedUser}       
            /> 
          : 
            <div className='info'>
              <h3>In this demo you can compare your own typing behaviour with your friends! <br />
                  Let user 1 type the same sentence twice. After this, user 2 will repeat the same sentence such that you can compare your typing behaviour!
              </h3>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default App;