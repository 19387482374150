import React from 'react'
import Info from './InfoIcon';

export default function StartOver(props) {
    const onRadioChanged = props.onRadioChanged;
    const onStartOver = props.onStartOver;

    const changeRadio = (e) => {
        onRadioChanged(e.target.value);
    };

    const handleStartOver = () => {
        onStartOver(true)
    };

    return (
        <div className='input'>
            <div className='start-over'>
                <button className='start-over-button' onClick={handleStartOver}>
                Click this button to start over!
                </button>
            </div>
            <div className='radio'>
                <input type='radio' value={'individual'} name='radio' onChange={(e) => changeRadio(e)} defaultChecked /> Individual plots
                <input class='second-input' type='radio' value={'combined'} name='radio' onChange={(e) => changeRadio(e)}/> Combined plot
                <Info />
            </div>
        </div>
    )
}
