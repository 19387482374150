import React, {useState} from 'react';

const Input = (props) => {
    const onSetInput1 = props.onSetInput1;
    const onSetInput2 = props.onSetInput2;
    const onSetInput3 = props.onSetInput3;

    const [userCount, setUserCount] = useState(1);
    const [input, setInput] = useState('');
    const [inputAr, setInputAr] = useState([]);
    const [pressed, setPressed] = useState([]);

    // Handle submit when clicking the button
    const handleSubmit = (e) => {
        // Change the user message and user id
        // For each letter in the data, add a new start and end time, 
        // relative to the start time of the first letter
        inputAr.map((d,i) => { 
            return (
                Object.assign(d, {
                    flightTime: i === 0 ? 0 : d.up - inputAr[i-1].down,
                    relativeStart : i === 0 ? 0 : Math.round((d.down - inputAr[i-1].down) * 100) / 100,
                    relativeEnd : i === 0 ? Math.round(d.duration * 100) / 100 : Math.round((d.up - d.down + (d.down - inputAr[i-1].down)) * 100) / 100
                })
            )
        });

        // Push the input to the parent component
        if (userCount === 1) {
            onSetInput1(inputAr);
            onSetInput2([]);
            onSetInput3([]);
        } else if (userCount === 2) {
            onSetInput2(inputAr);
        } else {
            onSetInput3(inputAr);
        };
        
        setInputAr([]);
        setInput('');

        // Change the user
        setUserCount(userCount === 3 ? 1 : userCount + 1) 
    };

    // Clear the input when clicking in input field
    const clearInput = (e) => {
        e.target.value = '';
        setInput(e.target.value);
        setInputAr([]);
    };

    // Check if key is enter/tab/shift/ctrl/alt/pause/caps lock
    const forbiddenKey = (keyCode) => {
        const forbidden = [9, 13, 16, 17, 18, 19, 20];

        if (forbidden.includes(keyCode)) {
            return true;
        } else {
            return false;
        };
    };

    // On key down
    const keyDown = (e) => {
        if (forbiddenKey(e.keyCode)) {
            // Do nothing
        }else {
            // Remove last item if backspace & items in input array
            if (e.keyCode === 8) {
                if (inputAr.length > 0) {
                    inputAr.pop();
                    setInputAr(inputAr);
                };
            } else {
                // Create object to store in the input array
                let obj = {
                    type: e.type,
                    key: e.key.toUpperCase(),
                    keycode: e.keyCode,
                    time: e.timeStamp
                };

                // Add pressed key to array
                if (pressed.length !== 0) {
                    setPressed([...pressed, obj])
                } else {
                    setPressed([obj])
                };
            };            
        };
    };

    // On key up
    const keyUp = (e) => {
        if (forbiddenKey(e.keyCode)) {
            // do nothing
        } else {
            // If key not in pressed array do nothing
            if (!(pressed.some(o => o.keycode === e.keyCode))) {
                return;
            } else {
                let down = pressed.find(o => {
                    return o.keycode === e.keyCode
                });

                let keystroke = {
                    id: inputAr.length + 1,
                    key: e.key.toUpperCase(),
                    keycode: e.keyCode,
                    down: down.time,
                    up: e.timeStamp, 
                    duration : e.timeStamp - down.time,
                };

                // Update input array
                if (inputAr.length !== 0 ) {
                    setInputAr([...inputAr, keystroke])
                } else {
                    setInputAr([keystroke])
                };

                // Remove from pressed if keyup and keydown stored in input array
                setPressed(pressed.filter((d => d.keycode !== e.keyCode)))
            };
        };
    };

    return (
        <div className='input'>
            <form onSubmit={e => {e.preventDefault(); handleSubmit(e);}} autoComplete='off'>
                <h1>Hi user {userCount === 1 ? 1 : userCount - 1}, please type {userCount === 1 ? 'some text!' : (userCount === 2 ? 'the same text again!' : 'the same text as the previous user')}</h1>
                <input 
                    id='input-left' 
                    type='text'
                    placeholder='Type here...'
                    onChange={e => setInput(e.target.value)}
                    onKeyDown={e => keyDown(e)}   
                    onKeyUp={e => keyUp(e)}
                    value={input}
                    onFocus={e => clearInput(e)}
                    minLength='3'
                    maxLength='40'
                    className='inputText'
                />

                <button
                    className='button'
                    type='submit' 
                    disabled={(input.length !== (inputAr.slice(0, 40)).length || (inputAr.slice(0, 40)).length === 0 )? true : false}
                >
                    Visualize
                </button>
            </form>
        </div>
    )
}

export default Input;