import { IconButton } from '@material-ui/core'
import React from 'react'
import logo from '../intense_logo.jpg'

export default function Logo() {
    return (
        <div className='logo'>
            <img src={logo} className='logoImage' alt='Logo' />   
        </div>
    )
};