import React from 'react';
import Box from '@mui/material/Box';

export default function Legend(props) {
    const onSetSelectedUser = props.onSetSelectedUser;

    const changeUser = (e) => {
        onSetSelectedUser(e.target.value)
    }

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box
                width={25}
                height={25}
                mr={1}
                sx={{
                    backgroundImage: `url("https://bit.ly/3n4RaT2")`,
                    backgroundSize: 'cover' 
                }}>
            </Box>
            Outlier
            <Box
                bgcolor={'#11384A'}
                width={25}
                height={25}
                ml={4}
                mr={1}
                sx={{opacity: 0.5}}>
            </Box>
            User 1
            <Box
                bgcolor={'#0063B2FF'}
                width={25}
                height={25}
                ml={4}
                mr={1}
                sx={{opacity: 0.5}}>
            </Box>
            <input class='first-input' type='radio' value={'1'} name='user' onChange={(e) => changeUser(e)} defaultChecked /> User 1
            <input class='second-input' type='radio' value={'2'} name='user' onChange={(e) => changeUser(e)}/>User 2
        </Box>
    )
};